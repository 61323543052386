import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import CookiePage from "../../components/legal/agreements/cookie-policy"
const CookiePolicy = () => {
  return (
    <Layout>
      <Seo
        title="Linux Shared Web Hosting, Linux Unlimited Domain Web Hosting - Motherepos"
        desc="Motherepos offers linux hosting various plans with single domain and multiple domain linux shared web hosting"
        pathname="/legal/agreements/cookie-policy/"
        keywords="Linux hosting, linux shared hosting, web hosting, linux web hosting"
      />
      <CookiePage />
    </Layout>
  )
}

export default CookiePolicy
